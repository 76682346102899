import React from "react"

import styles from "./Button.module.css"

const ButtonView = ({ children, handleClick, disabled = false }) => {
  return (
    <button className={styles.button} onClick={handleClick} disabled={disabled}>
      {children}
    </button>
  )
}

export default ButtonView
