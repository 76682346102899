/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useCallback, useState } from "react"
import { Link, navigate } from "gatsby"
import { scroller as scroll } from "react-scroll"
import PropTypes from "prop-types"
import className from "classnames"
import * as pathnames from "../../constants/pathnames"

import Button from "../UI/Button"

import styles from "./Header.module.css"
import logo from "../../assets/images/logo.png"

const cx = className.bind(styles)

const navigateTo = (location, to) => {
  if (location.pathname === pathnames.home) {
    scroll.scrollTo(to, {
      duration: 400,
      delay: 100,
      smooth: true,
      offset: -70,
    })
  } else {
    const state = {}
    if (to === "howItWorks") {
      state.howItWorks = true
    }
    if (to === "aboutUs") {
      state.aboutUs = true
    }
    navigate(pathnames.home, { replace: true, state })
  }
}

const HeaderView = ({ location }) => {
  const [visible, setVisible] = useState(false)
  const buttonRef = useRef(null)

  const onClick = useCallback(
    e => {
      if (
        e.target === buttonRef.current ||
        buttonRef.current.contains(e.target)
      ) {
        e.stopPropagation()
        setVisible(!visible)
      } else {
        setVisible(false)
      }
    },
    [visible]
  )

  const onHowItWorks = useCallback(() => {
    navigateTo(location, "howItWorks")
  }, [location])

  const onAboutUs = useCallback(() => {
    navigateTo(location, "aboutUs")
  }, [location])

  useEffect(() => {
    buttonRef.current.addEventListener("click", onClick)

    return () => {
      buttonRef.current.removeEventListener("click", onClick)
    }
  }, [visible, buttonRef])

  useEffect(() => {
    if (visible) {
      document.addEventListener("click", onClick)
    }

    return () => {
      if (visible) {
        document.removeEventListener("click", onClick)
      }
    }
  }, [visible])

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <Link to={pathnames.home} className={styles.logo}>
          <img src={logo} alt="logo" />
        </Link>
        <button
          type="button"
          className={cx([styles.toggle], { [styles.active]: visible })}
          onClick={onClick}
          ref={buttonRef}
        >
          <s className={styles.bar} />
          <s className={styles.bar} />
          <s className={styles.bar} />
        </button>
        <div className={cx([styles.menuWrap], { [styles.show]: visible })}>
          <div className={styles.menu}>
            <div className={styles.menuItem}>
              <a onClick={onHowItWorks} className={styles.menuLink}>
                HOW IT WORKS
              </a>
            </div>

            <div className={styles.menuItem}>
              <a onClick={onAboutUs} className={styles.menuLink}>
                ABOUT US
              </a>
            </div>

            <div className={styles.menuItem}>
              <Link to={pathnames.ratesAndFees} className={styles.menuLink}>
                RATES AND FEES
              </Link>
            </div>
            <div className={cx([styles.menuItem], [styles.hidden])}>
              <Button>GET STARTED</Button>
            </div>
          </div>
          <a className={styles.getStarted} href={pathnames.getStarted}>
            <Button>GET STARTED</Button>
          </a>
        </div>
      </div>
    </header>
  )
}

HeaderView.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HeaderView
