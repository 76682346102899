const prefix = ""

const home = `${prefix}/`
const aboutUs = `${prefix}/about-us`
const responsibleLending = `${prefix}/responsible-lending`
const policies = `${prefix}/policies`
const getStarted = `${prefix}/get-started`
const privacyPolicy = `${prefix}/privacy-policy`
const termsConditions = `${prefix}/terms-conditions`
const legalDisclaimer = `${prefix}/legal-disclaimer`
const eConsent = `${prefix}/e-consent`
const unsubscribe = `${prefix}/unsubscribe`
const contactUs = `${prefix}/contact-us`
const ratesAndFees = `${prefix}/rates-and-fees`

export {
  home,
  privacyPolicy,
  aboutUs,
  termsConditions,
  legalDisclaimer,
  eConsent,
  unsubscribe,
  ratesAndFees,
  contactUs,
  responsibleLending,
  policies,
  getStarted,
}
